import React from 'react';
import logo from './logo_rocketlit.png';
import logoText from './logo_rocketlit_alternate.png';
import './rocketlit.css';

const Rocketlit = () => {

    const url = 'https://www.rocketlit.com';

    return (
        <div className="panel_rocketlit panel_project">
            <div className="panel_hero">
                <div className="content-width">
                    <img className="logo_rocketlit"  src={logo} height="150" alt="RocketLit Logo" />
                    <h1>Science and History tailored to each student's reading level</h1>
                    <a target="_blank" href={url} rel="noopener noreferrer">
                        <button type="button" className="button3 cta">Experience RocketLit</button>
                    </a>
                </div>
            </div>
            <div className="skew-panel contentBox-grape">
                <div className="skew-background contentBox-grape"></div>
                <div className="relative content-width white">
                    <div className="project-details">
                        <a target="_blank" href={url} rel="noopener noreferrer"><img className="logo_rocketlit" src={logoText} alt="RocketLit Logo" height="100" /></a>
                        <p><strong>Launched Summer 2016</strong></p>
                        <a className="project-link" target="_blank" href={url} rel="noopener noreferrer">Visit RocketLit.com</a>
                    </div>
                    <div className="project-summary">
                        <p>A web app for science and history teachers that tailors assignments
                        to each student's specific reading level. So, if a student falls behind
                        on reading... they won't fall behind on learning.</p>
                        <p>Co-Founded, Y-Combinator Backed, Featured in the Huffington Post <span class="nowrap">and Forbes</span></p>
                        <ul className="list_project-features">
                            <li>
                                <div className="project-feature">

                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="clear"></div>
                </div>
            </div>
        </div>


    )
}


export default Rocketlit;