import React from 'react';

const Contact = () => {

    return (
        <h1>Contact</h1>
    )

}


export default Contact;