import React from 'react';

const Projects = () => {

    return (
        <h1>Projects</h1>
    )

}


export default Projects;