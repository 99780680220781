import React from 'react';
import './innerorbit.css';
import logo from './logo_inner-orbit_icon.png';
import logoText from './logo_innerorbit.png';


const Innerorbit = () => {

    const url= 'https://www.innerorbit.com';

    return (
        <div className="panel_innerorbit panel_project">
            <div className="panel_hero">
                <div className="textGradient">
                    <div className="content-width">
                        <div className="panel_heading">
                            <div className="panel_left">
                                <img className="logo_innerorbit" src={logo} alt="Inner Orbit Logo" />
                            </div>
                            <div className="panel_right">
                                <h1 className="text-shadow"><span>Inner</span>Orbit</h1>
                                <h2>NGSS Formative Assessments</h2>
                                <a target="_blank" href={url} rel="noopener noreferrer">
                                    <button type="button" className="button3 cta">Experience InnerOrbit</button>
                                </a>
                            </div>
                            <div className="clear"></div>
                        </div>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
            <div className="skew-panel contentBox-periwinkle">
                <div className="skew-background contentBox-periwinkle"></div>
                <div className="relative content-width">
                    <div className="project-details">
                        <a target="_blank" href={url} rel="noopener noreferrer"><img className="logo_innerorbit" src={logoText} alt="Inner Orbit Logo"  /></a>
                        <p><strong>Launched Fall 2018</strong></p>
                        <a className="project-link" target="_blank" href={url} rel="noopener noreferrer">Visit InnerOrbit.com</a>
                    </div>
                    <div className="project-summary">
                        <p>A web app for science teachers to quickly and easily create rigorous assessments
                        for their students, in turn receiving insanely detailed data from intuitive and truly <span class="nowrap">useful reports.</span></p>
                        <p>Second Product from RocketLit, Pairs Fantastically with <span className="nowrap">Flagship App</span></p>
                    </div>
                    <div className="clear"></div>
                </div>
            </div>
        </div>
    )
}


export default Innerorbit;