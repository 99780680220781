const initState = {
    projects: [
        {id: 1, title: 'yo'},
        {id: 2, title: 'yo2'}
    ]
};


const projectReducer = (state = initState, action) => {
    return state;
}

export default projectReducer

